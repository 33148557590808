<template>
  <div class="leftBox">
    <div class="left">
      <h5>内容资讯</h5>
      <div class="contentBox">
        <h6  @click="handleExpand">
          党建活动
          <img
            src="@/assets/home/zhankai.png"
            alt=""
            v-if="activityShow"
          />
          <img
            src="@/assets/home/zhankai1.png"
            alt=""
            v-else
          />
        </h6>
        <ul v-if="activityShow" class="activity">
          <li
            v-for="(item, index) in contentList"
            :key="index"
            @click="handleActivity(index)"
          >
            <div class="imgK">
              <img :src="item.annexPath" alt="" />
            </div>
            <div>
              <p :class="active == index ? 'active' : ''">
                {{ item.activityName }}
              </p>
              <p :class="active == index ? 'active' : ''" class="p1">
                {{ item.activityTheme }}
              </p>
            </div>
          </li>
        </ul>
        <h6  
          @click="handleLearn"
           >
          党建学习
          <img src="@/assets/home/zhankai.png"  v-if="learnShow" alt="" />
          <img src="@/assets/home/zhankai1.png"  v-else alt="" />
        </h6>
        <ul v-if="learnShow" class="activity">
          <li
            v-for="(item, index) in learnList"
            :key="index"
            @click="handleLearnInfo(index)"
          >
            <div class="imgK">
              <img :src="item.learningImage" alt="" />
            </div>
            <div>
              <p :class="activeLearn == index ? 'active' : ''">
                {{ item.learningTitle }}
              </p>
              <p :class="activeLearn == index ? 'active' : ''" class="p1">
                {{ item.learningSubtitle }}
              </p>
            </div>
          </li>
        </ul>
        <!-- <h6  @click="handleInformShow">
          通知公告
          <img
            src="@/assets/home/zhankai.png"
            alt=""
            v-if="informShow"
          />
          <img
            src="@/assets/home/zhankai1.png"
            alt=""
            v-else
          />
        </h6>
        <template>
          <ul v-if="informShow" class="Inform">
            <li
              v-for="(item, index) in informList"
              :key="index"
              @click="handleInform(index)"
              :class="index % 2 == 0 ? 'liBg' : 'liBgs'"
            >
              <div>
                <p class="p1" :class="activeInfo == index ? 'active' : ''">
                 {{ item.newsAnnouncementTitle }}
                </p>
              </div>
            </li>
          </ul>
        </template> -->
      </div>
    </div>
    
    <div class="right" >
      <button class="return" @click="returnto">返回</button>
      <h5>{{info.title}}</h5>
      
      <div class="time">{{info.createDate}}</div>
      <div>
        <div class="contentBox" v-html="info.activities">
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: '',
      activeLearn: '',
      activeInfo: '',
      contentList: [
        {
          imgs: require("@/assets/partybuilding/miaomiao.jpg"),
          title: "学习党史",
          content: "光辉党史照千秋，中国共产党是中国革命和建设事 业的领 导核心",
        },
        {
          imgs: require("@/assets/partybuilding/miaomiao.jpg"),
          title: "重走长征路",
          content:
            "光辉党成为中国共产党这个先进组织的一员。这条道路在 我眼前愈加清晰",
        },
        {
          imgs: require("@/assets/partybuilding/miaomiao.jpg"),
          title: "观看党史纪录片",
          content:
            "在前行路上永远跟党走，是以人民为中心打赢疫情 防控阻击战的历史结论，是让9899万农村贫困人...",
        },
        {
          imgs: require("@/assets/partybuilding/miaomiao.jpg"),
          title: "拜访老党员",
          content:
            "在前行路上永远跟党走，是以人民为中心打赢疫情 防控阻击战的历史结论，是让9899万农村贫困人...",
        },
        {
          imgs: require("@/assets/partybuilding/miaomiao.jpg"),
          title: "学习党史",
          content: "光辉党史照千秋，中国共产党是中国革命和建设事 业的领 导核心",
        },
        {
          imgs: require("@/assets/partybuilding/miaomiao.jpg"),
          title: "学习党史",
          content: "光辉党史照千秋，中国共产党是中国革命和建设事 业的领 导核心",
        },
        {
          imgs: require("@/assets/partybuilding/miaomiao.jpg"),
          title: "学习党史",
          content: "光辉党史照千秋，中国共产党是中国革命和建设事 业的领 导核心",
        },
        {
          imgs: require("@/assets/partybuilding/miaomiao.jpg"),
          title: "学习党史",
          content: "光辉党史照千秋，中国共产党是中国革命和建设事 业的领 导核心",
        },
        {
          imgs: require("@/assets/partybuilding/miaomiao.jpg"),
          title: "学习党史",
          content: "光辉党史照千秋，中国共产党是中国革命和建设事 业的领 导核心",
        },
        {
          imgs: require("@/assets/partybuilding/miaomiao.jpg"),
          title: "学习党史",
          content: "光辉党史照千秋，中国共产党是中国革命和建设事 业的领 导核心",
        },
      ],
      informList: [
      ],
      learnList: [],
      info: '',
      activityShow: true,
      learnShow: false,
      informShow: false,
    };
  },
  computed: {},
  created() {
    this.getList();
    this.queryPageDataByParam();
    this.queryLearnList();
  },
  methods: {
    //通知公告
    returnto(){
      this.$router.push({
        path:'/partybuilding'
      })

    },
    async getList() {
      let data = {
        newsAnnouncementType: 1,
        announcementType: 0,
        pageNo: 1,
        pageSize: 10,
      };
      let res = await this.$api.partybuilding.conditionalPagingQuery(data);
      if (res.code == 200) {
        this.informList = res.data.dataList
      }
    },
    //党建活动
    async queryPageDataByParam() {
      let data = {};
      let res = await this.$api.partybuilding.activityInfo(data);
      if (res.code == 200) {
        this.contentList = res.data;
        if(this.$route.query.activityId) {
          this.active = this.contentList.findIndex(it => it.activityId == this.$route.query.activityId)
          this.info = this.contentList[this.active]
          this.info.title = this.info.activityName
        }
      }
    },
    async queryLearnList() {
      let data = {};
      let res = await this.$api.partybuilding.queryPageDataByParam(data);
      if (res.code == 200) {
        this.learnList = res.data.dataList;
        if(this.$route.query.learnId) {
          this.learnShow = true
          this.activityShow = false
          this.informShow = false
          this.activeLearn = this.learnList.findIndex(it => it.activityId == this.$route.query.activityId)
          this.info = this.learnList[this.activeLearn]
          this.info.title = this.info.learningTitle
        }
      }
    },
    handleExpand() {
      this.activityShow = !this.activityShow;
      if(this.activityShow) {
        this.learnShow = false
        this.informShow = false
      }
    },
    handleInformShow() {
      this.informShow = !this.informShow;
      if(this.informShow) {
        this.learnShow = false
        this.activityShow = false
      }
    },
    handleInform(row) {
      this.activeInfo = row;
      this.info = this.informList[this.activeInfo]
      this.info.title = this.info.newsAnnouncementTitle
      this.info.activities = this.info.content
    },
    handleActivity(idx) {
      this.active = idx;
      this.info = this.contentList[this.active]
      this.info.title = this.info.activityName
    },
    handleLearn() {
      this.learnShow = !this.learnShow
      if(this.learnShow) {
        this.activityShow = false
        this.informShow = false
      }
    },
    handleLearnInfo(idx) {
       this.activeLearn = idx;
       this.info = this.learnList[this.activeLearn]
        this.info.title = this.info.learningTitle
    }
  },
};
</script>

<style lang="scss" scoped>
.return{
  position: absolute;
  top: 0rem;
  right: 0rem;
  background-color:#191970 ;
  height: 50px;
  width: 100px;
  color: white;
  border-radius: 50px;
}
.leftBox {
  position: absolute;
  display: flex;
  top: 1rem;
  left: 0.22rem;
  color: #fff;
  .left {
    width: 5.4rem;
    height: calc(100vh - 3rem);
    // height: 9.2rem;
    background-image: url("../../../assets/partybuilding/activity.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    h5 {
      font-size: 0.3rem;
      font-family: "YouSheBiaoTiHei";
      padding-left: 0.55rem;
      padding-top: 0.05rem;
    }
    .imgs {
      position: absolute;
      right: 0.3rem;
      top: 0.25rem;
    }
    .contentBox {
      width: 5.2rem;
      position: absolute;
      top: 0.7rem;
      padding-left: 0.08rem;
      height: 8rem;
      h6 {
        cursor: pointer;
        position: relative;
        font-size: 0.2rem;
        margin-bottom: 0.1rem;
        height: 0.5rem;
        line-height: 0.5rem;
        padding-left: 0.2rem;
        background-image: url("../../../assets/partybuilding/title.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        img {
          position: absolute;
          right: 0.18rem;
          top: 0.15rem;
        }
      }
      .Inform {
        .liBg {
          background: linear-gradient(
            90deg,
            rgba(0, 216, 255, .2) 0%,
            rgba(0, 216, 255, 0) 100%
          );
        }
        .liBgs {
          background: transparent;
        }
        height: 6rem;
        overflow-x: hidden;
        margin-bottom: 0.1rem;
        li {
          height: 0.5rem;
          line-height: 0.5rem;
          cursor: pointer;
        }
        .p1 {
          font-size: 0.16rem;
          padding: 0 0.1rem;
          color: #abdafe;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          span {
            display: inline-block;
            color: #fff;
            font-weight: 700;
          }
        }
      }
      .Inform :nth-child(odd) {
        // background: linear-gradient(
        //   90deg,
        //   #00d8ff 0%,
        //   rgba(0, 216, 255, 0) 100%
        // );
      }
      // .Inform :nth-child(even) {
      //   li:nth-child(odd) {
      //     background: transparent;
      //   }
      // }
      .activity {
        height: 6rem;
        padding-left: 0.2rem;
        overflow-x: hidden;
        margin-bottom: 0.1rem;
        li {
          display: flex;
          align-items: center;
          margin-bottom: 0.18rem;
          cursor: pointer;
        }
        .p1 {
          width: 3.8rem;
          height: 0.4rem;
          margin-top: 0.05rem;
          font-size: 0.14rem;
          color: #abdafe;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .imgK {
        width: 0.8rem;
        height: 0.8rem;
        border: 1px solid #fff;
        margin-right: 0.1rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      p {
        font-size: 0.17rem;
      }
    }
  }
  .right {
    position: fixed;
    right: 0.3rem;
    width: calc(100vw - 6.5rem);
    height: calc(100vh - 3rem);
    overflow-y: scroll;
    h5 {
      font-size: 0.3rem;
      font-weight: 700;
      color: #fff;
      margin-bottom: 0.2rem;
    }
    .time {
      margin-bottom: 0.3rem;
      color: #abdafe;
    }
    .imgs {
      width: 7rem;
      height: 3.5rem;
      margin: auto;
      margin-bottom: 0.3rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .contentBox {
      p {
        text-indent: 2em;
        margin-bottom: 0.2rem;
        padding-right: 0.1rem;
      }
    }
  }
}
/* 滚动条样式 */
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 4px;
  height: 10px;
  //   background-color: #00d8ff;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px #08101d;
  // border-radius: 10px;
  background-color: #0b1933;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px #152847;
  background-color: #00d8ff;
}
.active {
  color: #fff !important;
  text-decoration: underline;
}
</style>