<template>
  <dv-full-screen-container class="bg">
    <Header></Header>
    <Left />
    <Footer></Footer> 
  </dv-full-screen-container>
</template>

<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
import Left from "./left";
export default {
  components: {
    Header,
    Footer,
    Left,
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.bg {
  background-image: url("../../assets/video/bg.png");
  // background-color: #000;
  // background-position: bottom center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>